.confirmBeforeApplication {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(94, 94, 94);
  font-size: 0.9rem;
  letter-spacing: 1px;
}
.explanationContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1em;
}
.confirmHeadingContainer {
  margin-top: 3em;
  text-align: center;
}
.applicationSectionHeading {
  font-size: 1.2rem;
  font-weight: 900;
  margin-top: 2em;
  margin-bottom: 0;
  width: 100%;
}
.confirmBeforeApplication hr {
  border-top: 1px solid rgb(0, 102, 255);
  width: 100%;
}
.requiredDocumentsContainer {
  outline: 1px solid rgb(170, 170, 170);
  width: 100%;
  max-width: 35rem;
  border-radius: 5px;
  padding: 1em;
}
.subHeading {
  font-weight: 900;
  font-size: 1.3rem;
}
.paymentRecsContainer {
  outline: 1px solid rgb(170, 170, 170);
  margin-top: 2em;
  border-radius: 3px;
  padding: 1em;
  width: 100%;
  max-width: 35rem;
}
.aboutDeviceReqs {
  outline: 1px solid rgb(170, 170, 170);
  width: 100%;
  max-width: 35rem;
  border-radius: 3px;
  padding: 1em;
  margin-top: 1em;
}
.networkRestrictions {
  outline: 1px solid rgb(170, 170, 170);
  width: 100%;
  max-width: 35rem;
  margin-top: 2em;
  border-radius: 3px;
  padding: 1em;
}
