.termDefContainer {
  display: flex;
  margin: 0.5em;
}
.termDefContainer > dt {
  min-width: 45%;
  text-align: end;
  font-size: 1rem;
}
.termDefContainer > dd {
  width: 70%;
  text-align: start;
  font-size: 1rem;
}
