.orderPlanCheckListContainer {
  border: 1px solid red;
  width: 100%;
  max-width: 700px;
}
.allValid {
  border: none;
}
.errorsHeading {
  text-align: center;
  font-weight: 800;
}
.errorsHeading::before {
  content: "!";
  background-color: rgb(220, 48, 48);
  font-size: 14px;
  color: white;
  padding: 0px 8px;
  border-radius: 100%;
  margin-right: 3px;
}
