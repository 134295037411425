.customerDetailsContainer {
  box-sizing: border-box;
  padding: 0.5em;
  width: 100%;
}
.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.formControl {
  box-sizing: border-box;
  border: 1px solid red;
  font-size: 1.2rem;
  padding: 0.75em;
  border-radius: 4px;
  width: 100%;
  margin: 1em 0;
}

.required::before {
  content: "必須";
  background-color: rgb(255, 179, 79);
  color: white;
  font-size: 0.8rem;
  padding: 0.1em 0.3em;
  border-radius: 10px;
  margin-right: 0.5em;
}
.notRequired::before {
  content: "任意";
  background-color: white;
  border: 1px solid rgb(255, 179, 79);
  color: rgb(255, 179, 79);
  font-size: 0.8rem;
  padding: 0.1em 0.3em;
  border-radius: 10px;
  margin-right: 0.5em;
}
.customerDetailsHeading {
  text-align: center;
}
.block {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
@media (min-width: 960px) {
  .inputBlock {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
  }
}
