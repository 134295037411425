.regInput {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.3rem;
  padding: 1em;
  border-radius: 4px;
  outline: 1px solid rgb(151, 151, 151);
  border: none;

  display: block;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  border-radius: 4px;
  margin: 0;
  border: none;
  transition: 200ms outline;
  cursor: pointer;
}
.justInput {
  width: 100%;
  position: relative;
}
.floatingLabel {
  position: absolute;
  font-size: 1.2rem;
  top: 1.5rem;
  left: 1rem;
  transition: 0.2s all;
  pointer-events: none;
}
.regInput:focus-visible {
  outline: 2px solid rgb(0, 162, 255);
}
.regInput:focus + .floatingLabel {
  top: -0.8rem;
  background-color: white;
  color: rgb(0, 162, 255);
}
.inputError {
  color: red;
  outline: 2px solid red;
}
.errorArea {
  color: red;
}
.successful {
  outline: 2px solid rgb(0, 218, 0);
}
.success {
  color: rgb(0, 218, 0);
}
.moveLabel {
  top: -0.8rem;
  background-color: white;
}
.labelError {
  color: red;
  animation: noInput 0.15s linear 0s 3 forwards;
}
.normalInfoArea {
  font-size: 0.9rem;
  height: 1.7rem;
  margin: 0;
  font-size: 0.8rem;
}
@keyframes noInput {
  0% {
    transform: translateX(0rem);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0rem);
  }
}
