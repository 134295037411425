.applicationProcessContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
/* steps indicator*/
.stepsContainer {
  box-sizing: border-box;
  margin-top: 1.5em;
  padding: 1em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.progressIndicator {
  border-radius: 100%;
  font-size: 0.8rem;
  display: inline-block;
  padding: 0.2em 0.7em 0.3em 0.7em;
  text-align: center;
  color: white;
  background-color: rgb(163, 163, 163);
  content: "\2713";
}
.workedOn {
  background-color: blue;
}
.applicationContainer {
  width: 100%;
}
@media (min-width: 990px) {
  .progressIndicator {
    border-radius: 100%;
    font-size: 1rem;
    display: inline-block;
    padding: 0.2em 0.7em 0.3em 0.7em;
    text-align: center;
    color: white;
    background-color: rgb(163, 163, 163);
    content: "\2713";
  }
}
