.mainNoticeBox {
  display: flex;
  flex-direction: column;
  height: 80dvh;
}
.noticeBox {
  background-color: white;
  box-sizing: border-box;
  position: relative;
  overflow: scroll;
  max-height: 90%;
}
.actionButtonsContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px 0;
}
.actionButtonsContainer > button {
  width: 100%;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  max-width: 200px;
  background-color: rgb(255, 72, 0);
  border: none;
  padding: 10px 0;
  font-weight: bold;
}
.mainModalHeading {
  font-size: 1rem;
  margin: 3rem 0 10px 0;
  display: block;
  text-align: center;
}
.mainModalSubHeading {
  font-size: 0.9rem;
  text-align: center;
  margin: 0;
}
.modalConditionalBreakpoint {
  display: block;
}
/* MODAL STEPS AND IMAGE STYLING */
.modalRegSteps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.numberedStepsHeading {
  color: rgb(255, 72, 0);
  font-size: 1.3rem;
  font-weight: 900;
  text-align: center;
}
.centerText {
  text-align: center;
}
.modalRegSteps > li {
  padding-left: 20px;
  font-size: 1em;
}
.numberedStepsContainer {
  background-color: rgb(245, 245, 245);
  padding: 2em 0px;
  margin: 2em 0px;
  border-radius: 0.5rem;
}
/* PICTURE PROCESS STYLING */
.modalPicSteps {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.modalPicSteps > div {
  width: 100%;
}
.modalPicStepHeading {
  color: rgb(255, 72, 0);
  font-size: 1.1rem;
  font-weight: 900;
}
.modalPicStepHighlight {
  color: rgb(255, 81, 0);
}
.picStepImage {
  max-width: 10rem;
}

.modalCloseButton {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: rgb(244, 244, 244);
  border: none;
  scale: 1;
}
.modalCloseButton > svg {
  border-radius: 100%;
  background-color: black;
  padding: 0.5em;
}
.modalPicStepIntro {
  font-weight: 900;
}
.ModalSearchButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ModalSearchButton > img {
  max-width: 1.5rem;
}
.ModalSearchButton button {
  background: linear-gradient(to right, rgb(252, 108, 12), rgb(255, 72, 0));
  border: none;
  color: white;
  padding: 0.5em;
  border-radius: 5px;
}

/* MODAL ORDER BUTTON */
.modalButtonContainer {
  margin: 1.5em 0px;
}
.modalButtonContainer > a {
  width: 100%;
  max-width: 30rem;
}
.modalButton {
  font-weight: 900;
  background-color: rgb(208, 255, 0);
  border-radius: 3rem;
  border: none;
  padding: 1em;

  box-shadow: 0 1px 2px rgb(100, 100, 100);
  cursor: pointer;
}
/* ANIMATIONS */
.ModalOpen {
  animation: openModal 0.15s ease-out forwards;
}

.ModalClosed {
  animation: closeModal 0.15s ease-out forwards;
  -webkit-animation: closeModal 1s ease-out forwards;
}

@keyframes openModal {
  0% {
    opacity: 0;
    width: 10rem;
    top: 45%;
    bottom: 15%;
    left: 40%;
  }

  100% {
    opacity: 1;
    top: 5%;
    bottom: 5%;
    left: 7%;
    width: 86%;
  }
}

@keyframes closeModal {
  0% {
    opacity: 0.5;
    top: 5%;
    bottom: 5%;
    left: 7%;
    width: 86%;
  }

  100% {
    opacity: 0;
    width: 5rem;
    top: 45%;
    bottom: 15%;
    left: 40%;
  }
}
@media (min-width: 960px) {
  .mainModalHeading {
    font-size: 1.5rem;
    margin-top: 3rem;
  }
  .mainModalSubHeading {
    font-size: 1rem;
  }
  .modalConditionalBreakpoint {
    display: inline;
  }
  /* MODAL STEPS AND IMAGE STYLING */
  .modalRegSteps > li {
    padding-left: 20px;
    font-size: 1.2em;
  }
  /* PICTURE PROCESS STYLING */
  .modalPicSteps {
    display: flex;
    flex-direction: row;
    gap: 2em;
  }
  /* MODAL ORDER BUTTON */
  .modalButtonContainer {
    margin: 1.5em 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .modalButtonContainer > a {
    width: 100%;
    max-width: 30rem;
  }
  .modalButton {
    font-weight: 900;
    background-color: rgb(208, 255, 0);
    border-radius: 1.5rem;
    border: none;
    padding: 1em;
    box-shadow: 0 1px 2px rgb(100, 100, 100);
    cursor: pointer;
  }
}
