.MNPFormWrapper {
  width: 100%;
  margin: auto;
}
.MNPFormDetails {
  width: 100%;
  max-width: 40rem;
  margin: auto;
}
.required {
  width: 100%;
}
.required::before {
  content: "必須";
  color: white;
  border-radius: 5px;
  background-color: rgb(255, 153, 70);
  font-size: 0.7rem;
  padding: 2px 5px;
  margin-right: 5px;
}
