.detailsBox {
  box-sizing: border-box;
  color: #636363;
  max-width: 40rem;
  border: none;
  padding: 1em;
  position: relative;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.monthlyFee > dl {
  display: flex;
  flex-direction: column;
}
.monthlyFee > dl > dt {
  font-size: 1.1rem;
  color: black;
  font-weight: 800;
}
.monthlyFee > dl > dd {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 800;
}
.feeFromJan {
  color: rgb(255, 127, 8);
  margin: 0;
}
.breakdownHeading {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 800;
}
.selectionTermDef > dl {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 800;
}
.detailHeading {
  font-weight: 800;
  font-size: 1.3rem;
  margin: 0;
  color: black;
}
.priceRange {
  font-weight: 800;
  font-size: 1.3rem;
  margin: 0;
}
.selectionTermDef > dl > dt {
  min-width: 40%;
  text-align: end;
}
.selectionTermDef > dl > dd {
  /* border: 1px solid blue; */
}
/* end of term def */
.detailSection > h3 {
  padding-bottom: 1em;
}
.detailSection > dl {
  display: flex;
}
.listContainer > h3 {
}
.listContainer > ul {
  padding: 0 1.1em;
}
.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  max-width: 3rem;
  background-color: white;
  border: none;
}
.closeButton > img {
  width: 100%;
}
.detailSection > dl {
  display: flex;
  font-weight: 800;
}
.detailSection > dl > dt {
  width: 50%;
  text-align: end;
}
