.reUploadContainer {
  margin: 0 1rem;
  border-radius: 1.5rem;
}
.reUploadLabelRipple {
  width: 100%;
  border-radius: 1.5rem;
}
.reUploadSection {
  border: 1px solid rgb(95, 95, 95);
  border-radius: 1.5rem;
  width: 100%;
  text-align: center;
  padding: 0.3rem 0;
  cursor: pointer;
}
