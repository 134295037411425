.inputContainer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.placeHolder {
  position: absolute;
  left: 10px;
  top: 0rem;
  pointer-events: none;
  transition: 0.4s all;
}
.inputElement {
  box-sizing: border-box;
  padding: 1em;
  font-size: 1rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(138, 138, 138);
}
.keepLabel {
  top: 0rem;
}
.inputElement:focus + .placeHolder {
  top: -1.4rem;
  background-color: white;
  font-size: 0.8rem;
}
.dropLabel {
  top: 0rem;
  background-color: white;
}
.keepLabel {
  top: -1.4rem;
  background-color: white;
  font-size: 0.8rem;
}
.inputElement:focus {
  border: 2px solid blue;
  outline: none;
}
.error {
  border: 2px solid #ff2d2d !important;
  color: #ff2d2d;
}
.labelError {
  color: #ff2d2d;
}
.labelSuccess {
  color: green;
}
.error::placeholder {
  color: #ff2d2d;
}
.success {
  border: 2px solid green !important;
}
