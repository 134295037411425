.orderPageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.orderPageMainContent {
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  max-width: 60em;
  position: relative;
}
