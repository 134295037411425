.applicationTypeContainer {
  width: 100%;
  margin: 0em 0;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
/* .applicationContainerHeading {
  font-size: 1.3rem;
  margin: 0;
  width: 100%;
}
.applicationContainerHeading::before {
  content: "必須";
  background-color: rgb(255, 157, 65);
  border-radius: 10px;
  color: white;
  font-size: 0.7rem;
  padding: 0.1em 0.4em;
  font-weight: 800;
} */
.deliveryMode {
  accent-color: rgb(33, 155, 255);
  scale: 1.3;
}
.deliveryMode:focus {
  outline: none;
  border: none;
  accent-color: rgb(33, 155, 255);
}
.rippleWrapper {
  width: 100%;
}
.MNPLabel label {
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid rgb(33, 155, 255);
  width: 100%;
  padding: 0.8em;
  border-radius: 4px;
  cursor: pointer;
}

.labelFocus {
  background-color: rgb(198, 229, 255);
}
.applicationTypes {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MNPLabel {
  margin: 0.5em 0;
  width: 100%;
  max-width: 40rem;
}
.formWrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 40rem;
  margin: 2em 0;
}
