.detailConfirmationPage {
  max-width: 45rem;
  margin: auto;
}
.detailConfirmationPageHeading {
  text-align: center;
}
.termDefContainer {
  display: flex;
  justify-content: stretch;
  max-width: 50rem;
  margin: auto;
}
.termDefContainer * {
  width: 100%;
  font-size: 0.9rem;
  padding: 0.5em 1em;
  margin: 0.5em 0;
}
.detailSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(0, 162, 255);
  padding: 5px 0px;
  cursor: pointer;
}
.editButtonContainer {
  border: 1px solid rgb(0, 162, 255);
  border-radius: 20px;
}
.sendFailed {
  position: relative;
  display: flex;
  justify-content: center;
}
.sendFailedInnerContainer {
  width: 100%;
  max-width: 18rem;
  position: relative;
}
