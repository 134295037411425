.orderPlanContainer {
  width: 100%;
}
.orderPlanContainer > * {
  width: 100%;
  height: 100%;
}
.orderPlanItem {
  border: 1px solid #94c6ff;
  width: 100%;
  border-radius: 3px;
  padding: 1em;
  cursor: pointer;
  background-color: white;
}
.orderPlanItem:disabled * {
  color: rgb(153, 153, 153);
}

.planIconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.mainPlanText {
  text-align: center;
  font-size: 1rem;
  color: #545454;
  font-weight: 700;
  /* margin: 0.1em; */
}

.planDetails,
.planStartingPrice {
  text-align: start;
  text-align: center;
  margin: 1em 0;
}
.buttonActive {
  background-color: #b3edff;
  outline: none;
  border: none;
}
