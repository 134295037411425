.settingUpAccountContainer {
  color: rgb(58, 58, 58);
}
.settingUpAccountHeading {
  text-align: center;
}
.inStructionContainer {
  border-bottom: 1px solid #636363;
  padding-bottom: 2em;
}
.inStructionContainer > p {
  margin: 0;
  font-size: 0.9rem;
}
.infoBox > p {
  margin: 0;
}
.setUpGuidelineInstruction {
  font-weight: 900;
  font-size: 0.9rem;
}
.required::before {
  content: "必須";
  background-color: rgb(255, 115, 0);
  color: white;
  padding: 0.2em 0.3em;
  border-radius: 10px;
  margin-right: 0.5em;
}
.accountCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin-bottom: 5em;
}
.settingUpAccountCard {
  box-sizing: border-box;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 10px;
  max-width: 40em;
  padding: 1em;
  margin: auto;
}
.accountSetUpButtonContainer {
  display: flex;
  justify-content: center;
}
.buttonWrap {
  width: 100%;
  max-width: 30rem;
  margin: 2em auto;
}
.accountSetUpButton {
  width: 100%;
  max-width: 30rem;
  cursor: pointer;
  color: #0077ff;
  border: 1px solid #0077ff;
  background-color: white;
  font-weight: 700;
  padding: 0.4em 0;
  border-radius: 50px;
}
.accountInstruction {
  text-align: center;
}
.accountNumber {
  text-align: center;
  font-weight: 900;
  font-size: 1.2rem;
}
.noticeBox {
  padding: 1px 10px;
  font-size: 0.8rem;
  border: 2px solid #ffa4a4;
  max-width: 700px;
  margin: auto;
}
