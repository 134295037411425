.webRegisteringContainer {
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid rgb(126, 126, 126);
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding: 1em;
}
.webRegisteringContainer > h2 {
  font-size: 1.2rem;
}
.webRegisteringContainer > p {
  margin: 0;
}
/*
temp styline*/
.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
}
.ReturnToTop {
  border: 1px solid rgb(3, 112, 255);
  width: 100%;
  max-width: 30rem;
  padding: 0.4em 0;
  background-color: white;
  border-radius: 1rem;
  box-sizing: border-box;
  color: #0077ff;
  font-weight: 800;
  cursor: pointer;
}
/*
temp styline*/
