.MNPExpDatesContainer {
  position: relative;
}
.MNPExpDatesLabel {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0.3em 0.5em;
  border: 1px solid #8a8a8a;
  border-radius: 4px;
  cursor: pointer;
}
.MNPExpDatesWrapper {
  background-color: burlywood;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgb(214, 214, 214);
  position: absolute;
  top: -0.5em;
  z-index: 1;
  width: 100%;
  max-height: 25em;
  overflow-y: scroll;
}
.MNPExpDates {
  list-style: none;
  padding: 0;
  margin: 0;
}
.MNPExpDates > li {
  margin: 0;
  padding: 0;
}
.MNPExpDates > li:last-child .MNPExpDate {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.MNPExpDates > li:first-child .MNPExpDate {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.rippleComponent {
  width: 100%;
}
.MNPExpDate {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0.7em 1em;
  border: none;
  transition: background-color 0.4s;
}
.MNPExpDate:hover {
  background-color: rgb(115, 192, 255);
}
.active {
  background-color: rgb(115, 192, 255);
}
.MNPExpDate:focus {
  outline: none;
}
.success {
  border: 2px solid rgb(0, 201, 0);
}
.attachList {
  animation: attachList 250ms ease-in 0s 1 forwards;
}
.removeList {
  animation: removeList 250ms ease-out 0s 1 forwards;
}
.arrowImage {
  transition: all 500ms;
}
.open {
  transform: rotate(180deg);
}
.close {
  transform: rotate(0deg);
}
.expDates {
  box-sizing: border-box;
  margin: 0.4em 0 0 1em;
}
.expDateExtraInfo {
  font-size: 0.7rem;
  padding: 0;
  margin: 0 0 0 1rem;
}
@keyframes attachList {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes removeList {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
