.submissionError {
  box-sizing: border-box;
  border: none;
  width: 100%;
  max-width: 18rem;
  color: rgb(245, 245, 245);
  background-color: rgba(224, 0, 0, 0.8);
  padding: 1em;
  border-radius: 0.5em;
  position: absolute;
  top: -3rem;
  animation: entering 2.99s linear 0s 1 forwards;
}
.submissionError > p {
  font-size: 0.7rem;
  margin: 0;
}

@keyframes entering {
  0% {
    opacity: 0;
    transform: translateX(30rem);
  }
  15% {
    opacity: 0.5;
    transform: translateX(-3rem);
  }
  20% {
    opacity: 0.9;
    transform: translateX(0rem);
  }
  40% {
    opacity: 1;
    transform: translateX(0rem);
  }
  50% {
    opacity: 1;
    transform: translateX(0rem);
  }
  70% {
    opacity: 1;
    transform: translateX(0rem);
    left: 0rem;
  }
  80% {
    opacity: 0.5;
    transform: translateX(-3rem);
  }
  90% {
    opacity: 0.3;
    transform: translateX(5rem);
  }
  100% {
    opacity: 0;
    transform: translateX(10rem);
  }
}
