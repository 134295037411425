.topBannerContainer {
  width: 100%;
  background-color: rgb(107, 188, 255);
  box-shadow: 0 5px 5px rgb(202, 202, 202);
  display: flex;
  justify-content: center;
}
.topBannerImage {
  max-width: 10rem;
  margin-left: 1rem;
}
.topBannerImageContainer {
  width: 90%;
}
@media (min-width: 1024px) {
  .topBannerImageContainer {
    width: 70%;
  }
}
