.customerDetailInputContainer {
  width: 100%;
  position: relative;
}
.customerDetailInput {
  box-sizing: border-box;
  outline: 1px solid rgb(151, 151, 151);
  font-size: 1.2rem;
  padding: 0.75em;
  border-radius: 4px;
  width: 100%;
  margin: 0;
  border: none;
  transition: 200ms outline;
}
.floatingLabel {
  font-size: 18px;
  color: #000;
  background-color: white;
  position: absolute;
  pointer-events: none;
  top: 0.9rem;
  left: 12px;
  transition: all 0.1s ease;
  max-height: 1rem;
}
.customerDetailInput:focus-visible + .floatingLabel,
.moveLabel {
  top: -0.75rem;
  font-size: 11px;
  opacity: 1;
  color: #404040;
  font-size: 13px;
}
.moveLabel {
  top: -0.75rem;
  font-size: 11px;
  opacity: 1;
  color: #404040;
  font-size: 13px;
}
.entryInfoContainer {
  margin-top: 3px;
  height: 2rem;
  position: relative;
  padding: 0;
  margin: 0;
}

.entryInstruction {
  font-size: 13px;
  font-weight: 100;
  color: rgb(66, 66, 66);
  top: 0px;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}
.customerDetailInput:focus-visible {
  outline: 2px solid rgb(0, 99, 212);
  border: none;
}
.errorArea {
  color: red;
  margin: 0;
  margin-top: 4px;
  font-size: 0.8rem;
  margin-left: 10px;
}

.error {
  color: red;
  outline: 2px solid red;
}
.error + .floatingLabel {
  color: red;
}
.success {
  outline: 2px solid rgb(0, 168, 0);
}
.appear {
  animation: appear 0.3s linear 0s 1 forwards;
}
.disappear {
  animation: disappear 0.1s linear 0s 1 forwards;
}
.emptyError {
  animation: emptyError 0.1s linear 0s 3 forwards;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes emptyError {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  50% {
    transform: translateX(4px);
  }
  10% {
    left: 2;
    transform: translateX(0px);
  }
}
