.agreementlinkscontainer {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.agreementlinkscontainer a {
  color: rgb(0, 134, 230);
}
.agreementlinkscontainer input {
  accent-color: #0086e6;
}

.agreementlinkscontainer a:visited {
  color: rgb(0, 134, 230);
}
