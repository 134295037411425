.applicationGeneralContainer {
  box-sizing: border-box;
  padding: 1em 1em;
  margin-top: 5em;
  width: 100%;
}
.applicationDeliveryMode {
  margin: 2em 0.5em;
  display: flex;
  flex-direction: column;
}
.applicationDeliveryMode > label {
  display: inline-block;
  margin: 0.5em 0;
}

.deliveryMode:hover {
  padding: 1em;
}
.applicationDeliveryMode > label > input {
  width: 2rem;
  scale: 1.3;
  cursor: pointer;
  background: #ff572233;
  border-radius: 100%;
  accent-color: rgb(0, 110, 255);
}
.applicationContainerHeading {
  font-size: 1.3rem;
  margin: 0;
  width: 100%;
}
.applicationContainerHeading::before {
  content: "必須";
  background-color: rgb(255, 157, 65);
  border-radius: 10px;
  color: white;
  font-size: 0.7rem;
  padding: 0.1em 0.4em;
  font-weight: 800;
}
.simInfoContainer {
  box-sizing: border-box;
  padding: 1em 0;
  margin: 1em auto 1em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35rem;
}
@media (min-width: 960px) {
  .applicationDeliveryMode {
    flex-direction: row;
    justify-content: space-between;
  }
  .applicationDeliveryMode > label {
    width: 50%;
  }
  .simInfoContainer {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
