.idTypesContainer {
  position: relative;
}
.RippleContainer {
  width: 100%;
}
.idLabel {
  border: 1px solid rgb(136, 136, 136);
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: rgb(95, 95, 95);
  cursor: pointer;
}
.idLabel:hover {
  border: 1px solid black;
}
.idTypesList {
  list-style: none;
  background-color: white;
  box-shadow: 1px 1px 10px rgb(187, 187, 187);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  max-height: 15rem;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
}

.idType {
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  border: none;
  text-align: start;
  padding: 0.7rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  color: rgb(27, 27, 27);
}
.idType:hover {
  background-color: rgb(245, 245, 245);
}
.idType:disabled {
  color: rgb(146, 146, 146);
}
.showList {
  animation: showList 200ms ease-in 0s 1 forwards;
}
.hideList {
  animation: hideList 200ms ease-out 0s 1 forwards;
}
@keyframes showList {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hideList {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
