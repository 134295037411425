.agreementPageContainer {
  max-width: 1110px;
  max-height: 30rem;
  box-sizing: border-box;
  padding: 1.5em;
  overflow-y: scroll;
  margin: 2em 0;
}
.agreementPageHeading {
  text-align: center;
}
.agreementPageHeading > hr {
  border: 2px solid red;
  width: 10rem;
}
