.Footer {
  background-color: rgb(238, 238, 238);
  width: 100%;
  padding: 0.5em 0;
}
.copyRightText {
  text-align: center;
  font-size: 0.8rem;
  padding: 0.1rem;
}
