.fileUploadContainer {
  width: 100%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fileUploadSection {
  width: 100%;
  max-width: 30rem;
  margin: auto;
  border: 1px solid rgb(116, 116, 116);
  border-radius: 4px;
  /* padding: 0 1rem; */
}
.rippleDiv {
  width: 100%;
  max-width: 30rem;
  padding: 0;
  margin: auto;
  border: none;
}

.labelContainer {
  width: 100%;
  display: block;
  /* border: 1px solid #ccc; */
  /* margin:; */
  border: none;
  margin-bottom: 1px solid black;
}

/* // */
.fileUploadLabel {
  box-sizing: border-box;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(95, 95, 95);
  cursor: pointer;
  font-size: 0.8rem;
  max-width: 30rem;
  width: 100%;
  border: none;
  padding: 10px 1rem;
}
.labelDescContainer {
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 1rem;
}
.formControl {
  display: none;
}
.viewBoxContainer {
  box-sizing: border-box;
  max-width: 30rem;
  border-top: 1px solid rgb(114, 114, 114);
  margin: 1rem;
}
.viewImage {
  box-sizing: border-box;
  width: 100%;
}
.controlButton {
  box-sizing: border-box;
  border-top: 1px solid rgb(145, 145, 145);
  padding: 1rem 0.5rem;
  margin-top: 1rem;
}
.reUploadSection {
  background-color: white;
  border: 1px solid black;
  width: 100%;
  border-radius: 1.5rem;
  cursor: pointer;
  padding: 0.3rem 0;
  font-size: 0.9rem;
  display: block;
  text-align: center;
  margin: 0 1rem;
}

.arrowNormal {
  border-radius: 100%;
  animation: arrowNormal 0.2s linear 0s 1 forwards;
}
.arrowOpen {
  animation: arrowOpen 0.2s linear 0s 1 forwards;
}
/* .errorSectionSpan {
  color: red;
  display: block;
  font-size: 0.6rem;
  margin-left: 1rem;
} */
@keyframes arrowOpen {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes arrowNormal {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
