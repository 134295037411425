.block {
  margin-bottom: 2em;
  font-size: 1.3rem;
}
.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 1em 0;
}
.required::before {
  content: "必須";
  background-color: rgb(255, 179, 79);
  color: white;
  font-size: 0.8rem;
  padding: 0.1em 0.3em;
  border-radius: 10px;
  margin-right: 1em;
}

.dateSectionContainer {
  width: 100%;
  position: relative;
}

.listLabel {
  display: block;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  outline: 1px solid rgb(151, 151, 151);
  font-size: 1.2rem;
  padding: 0.75em;
  border: none;
  border-radius: 4px;
  transition: 200ms outline;
  cursor: pointer;
}
.dateSectionInputContainer {
  box-sizing: border-box;
  font-size: 1.2rem;
  width: 100%;
  margin: 0;
  cursor: pointer;
  max-height: 15rem;
  overflow-y: scroll;
  position: absolute;
  top: -1rem;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgb(192, 192, 192);
  z-index: 2;
}
.rippleWrap {
  width: 100%;
}
.InputItem {
  box-sizing: border-box;
  font-size: inherit;
  width: 100%;
  padding: 1rem 0.75rem;
  transition: 0.2s background-color;
  border: none;
  cursor: pointer;
}
.InputItem:hover,
.InputItem:focus {
  background-color: #bae6ff;
  outline: none;
}

.selectLabel {
  position: absolute;
}
.moveLabel {
  top: -1rem;
  background-color: white;
  color: rgb(0, 218, 0);
}
.error {
  color: red;
}
.success {
  outline: 2px solid rgb(0, 218, 0);
}
@media (min-width: 960px) {
  .inputBlock {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    margin: 1em 0;
  }
}
