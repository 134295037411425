.MNPSelectionsContainer {
  width: 100%;
}
.applicationContainerHeading {
  font-size: 1.3rem;
  margin: 0;
  margin-top: 2em;
  width: 100%;
}

.applicationContainerHeading::before {
  content: "必須";
  color: white;
  border-radius: 5px;
  background-color: rgb(255, 153, 70);
  font-size: 0.7rem;
  padding: 2px 5px;
  margin-right: 5px;
}
.description {
  color: rgb(94, 94, 94);
  font-size: 0.7rem;
}
