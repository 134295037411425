.checklistContainer {
  box-sizing: border-box;
  outline: 1px solid red;
  padding: 0.5em;
  width: 100%;
  max-width: 40rem;
  margin: auto;
}
.checklistContainer p {
  text-align: center;
}
.requirementList > li {
  font-size: 0.8rem;
}
.allValid {
  outline: none;
}
.checklistContainer p::before {
  content: "!";
  background-color: rgb(220, 48, 48);
  font-size: 14px;
  color: white;
  padding: 0px 8px;
  border-radius: 100%;
  margin-right: 3px;
}
