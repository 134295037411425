.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.documentSubmissionStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(87, 87, 87);
}

.documentSubmissionFormContainer {
  width: 100%;
}
.documentSubmissionHeading {
  text-align: center;
}

.idTypeContainer {
  max-width: 30rem;
  margin: auto;
  position: relative;
}
.idTypes {
  list-style: none;
  padding: 0;
  margin: auto;
}
.idTypes > li {
  /* text-align: center; */
}
.warningContainer {
  box-sizing: border-box;
  outline: 1px solid red;
  width: 100%;
  max-width: 40rem;
  margin: 1em auto;
  padding: 1em;
}
.warningContainer p {
  text-align: center;
  font-size: 1rem;
}
.warningContainer p::before {
  content: "!";
  background-color: rgb(220, 48, 48);
  font-size: 14px;
  color: white;
  padding: 0px 8px;
  border-radius: 100%;
  margin-right: 3px;
}
.warningsListContainer > li {
  font-size: 0.7rem;
}
.buttonContainer {
  width: 100%;
}
.required::before {
  content: "必須";
  color: white;
  border-radius: 5px;
  background-color: rgb(255, 153, 70);
  font-size: 0.7rem;
  padding: 2px 5px;
  margin-right: 5px;
}
.documentSubmissionInfo {
  display: block;
  font-size: 0.7rem;
  margin-left: 1.5rem;
  color: rgb(125, 125, 125);
}
.documentSubmissionInfo::before {
  content: "※";
  font-size: 0.8rem;
}
.optional::before {
  content: "任意";
  border: 1px solid rgb(255, 153, 70);
  color: rgb(255, 115, 1);
  border-radius: 5px;
  font-size: 0.7rem;
  padding: 2px 5px;
  margin-right: 5px;
  font-weight: 900;
}
