.simDetailsContainer {
  /* border: 1px solid red; */
}
.applicationContainerHeading {
  font-size: 1.3rem;
  margin: 0;
  width: 100%;
}
.applicationContainerHeading::before {
  content: "必須";
  background-color: rgb(255, 179, 79);
  border-radius: 10px;
  color: white;
  font-size: 0.7rem;
  padding: 0.1em 0.4em;
  font-weight: 800;
}
.simInfoContainer {
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35rem;
}
@media (min-width: 960px) {
  .applicationDeliveryMode {
    flex-direction: row;
    justify-content: space-between;
  }
  .applicationDeliveryMode > label {
    width: 50%;
  }
  .simInfoContainer {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
