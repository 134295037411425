.OrderPlanSelectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5e5e5e;
}
.orderPlanHeading {
  text-align: center;
  color: inherit;
}
.planOptionsDescription {
  width: 100%;
  font-size: 1.1rem;
  font-weight: 800;
}
.required:before {
  content: "必須";
  background-color: rgb(255, 153, 70);
  color: white;
  font-size: 0.7rem;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 5px;
}
.orderPlanCardsContainer {
  width: 100%;
}
.orderPlanContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
}

.explanationTextContainer {
  box-sizing: border-box;
  padding: 1em 2em;
  width: 100%;
  font-size: 0.7rem;
}
.explanationTextContainer > p {
  margin: 0;
  width: 100%;
}
.packetSelectionHeading {
  width: 100%;
  font-weight: 900;
}
.attentionContainer {
  outline: 2px solid red;
  padding: 1em;
  border-radius: 5px;
  color: red;
  font-weight: 700;
  margin: 1em 0;
  width: 100%;
}
.attentionContainer p {
  margin: 0;
  padding: 0;
}
.termsAndConditionsheading {
  text-align: start;
  width: 100%;
  font-size: 1rem;
}
.proceedButtonContainer {
  width: 100%;
  max-width: 40rem;
  margin: 3em 0;
}
.packetOptionsContainer {
  width: 100%;
}

.planPacketOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.planPacketOption {
  width: 100%;
  max-width: 40rem;
  border: none;
  outline: 1px solid rgb(42, 141, 255);
  background-color: white;
  padding: 0.7em;
  font-size: 1rem;
  text-align: start;
}
.linksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agreementAreaContainer > p {
  font-size: 0.8rem;
  font-weight: 500;
  color: red;
}

@media (min-width: 600px) {
  .orderPlanContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
    width: 100%;
  }
}
@media (min-width: 1260px) {
  .orderPlanContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    width: 100%;
  }

  .orderPlanContainer > div:not(:first-child) {
    width: 100%;
  }
}
