.dataSmsPlansContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.planPacketOption {
  width: 100%;
  border: none;
  border: 1px solid #2a8dff;
  background-color: white;
  padding: 0.7em;
  font-size: 1rem;
  text-align: start;
  cursor: pointer;
  border-radius: 3px;
}
.current {
  background-color: #a1cdff;
  outline: none;
  border: none;
}
.planContainer,
.planContainer > * {
  width: 100%;
  max-width: 40rem;
}
