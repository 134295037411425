.buttonContainer {
  display: flex;
  justify-content: center;
  margin: 2em 0 5em 0;
}
.buttonContainer > * {
  width: 100%;
  max-width: 30rem;
}
.progressButton {
  border-radius: 1rem;
  padding: 0.5em 0.3em;
  width: 100%;
  border: none;
  background-color: rgb(0, 132, 255);
  color: white;
  max-width: 30rem;
  cursor: pointer;
  margin: auto;
  font-size: 1.05rem;
}
.progressButton:disabled {
  background-color: rgb(190, 190, 190);
  color: rgb(46, 46, 46);
  cursor: auto;
}
