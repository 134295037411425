.fileUpButtonContainer {
  margin-bottom: 1rem;
  margin: 0 1rem;
}
.fileUpButtonContainer > * {
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 1.5rem;
}
.fileUpButton {
  width: 100%;
  border-radius: 1.5rem;
  cursor: pointer;
  font-size: 0.9rem;

  border: none;
  background-color: rgb(0, 89, 255);
  color: white;
  padding: 0.4rem 0;
}
