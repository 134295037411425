.MNPInputContainer {
  width: 100%;
}
.inputAndLabelWrapper {
  position: relative;
}
.inputLabel {
  margin: 3em 0;
  /* border: 1px solid red; */
  /* margin-left: 2em; */
  margin-left: 1em;
}
.inputElement {
  box-sizing: border-box;
  font-size: 1.3rem;
  padding: 0.5em 1em;
  width: 100%;
  border: 1px solid rgb(117, 117, 117);
  border-radius: 4px;
  margin-top: 0.5em;
}
.inputElement:focus::placeholder {
  color: #999;
}
.inputElement:not(:focus)::placeholder {
  color: transparent;
}

.moveableLabel {
  position: absolute;
  top: 1.3em;
  left: 1em;
  margin: 0;
  pointer-events: none;
  padding: 0em 0.2em;
  transition: all 0.3s;
  color: rgb(119, 119, 119);
}
.errorTextContainer {
  padding: 0;
  height: 1.5rem;
}
.errorTextContainer > p {
  padding: 0;
  font-size: 0.9rem;
  margin: 0;
  margin-left: 1em;
}
.instructionText {
}
.errorText {
  color: red;
}
.required::before {
  content: "";
  /* position:absolute;   */
}
.inputElement:focus {
  outline: none;
  border: 2px solid rgb(50, 132, 255);
}
.inputElement:focus + .moveableLabel {
  top: -0.1rem;
  background-color: white;
  color: rgb(50, 132, 255);
  font-size: 0.8rem;
}

.movelabel {
  top: -0.1rem;
  background-color: white;
  font-size: 0.8rem;
  /* Your styles here */
}
.error {
  color: red;
  border: 2px solid red;
}
.labelError {
  color: red;
}
.verified {
  border: 2px solid rgb(0, 201, 0);
}
.labelVerified {
  color: rgb(0, 201, 0);
}
/* .showInstructionText {
  animation: showInstruction 0.3s linear 0 1 forwards;
}
.hideInstructionText {
  transform: opacity(0);
  transition: all 0.4s;
}
.showErrorText {
  transform: opacity(1);
  transition: all 0.4s;
}
.hideErrorText {
  transform: opacity(0);
  transition: all 0.2s;
} */
/* @keyframes showInstruction {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
