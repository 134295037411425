.currentSelectionsContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 10px black;
  background-color: white;
}
.currentSelectionsContainer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.currentSelectionsContainer ul > li {
  box-sizing: border-box;
  width: 30%;
  text-align: center;
  font-size: 1.1rem;
  border-left: 1px solid rgb(165, 165, 165);
  border-right: 1px solid rgb(165, 165, 165);
  padding: 0.5em 0em;
}
.labelText {
  font-size: 0.9rem;
  display: block;
}

.currentSelectionsContainer ul > li:not(:last-child) {
  width: 50%;
}
.currentSelectionsContainer ul > li:last-child {
  width: 100%;
}
.selection {
  font-size: 1.1rem;
  font-weight: bolder;
  color: rgb(255, 149, 28);
}
.viewDetails {
  background-color: rgb(255, 149, 28);
  color: white;
  cursor: pointer;
}
.perMonth {
  color: black;
  font-size: 0.8rem;
}
@media (min-width: 768px) {
  .currentSelectionsContainer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .currentSelectionsContainer ul > li:not(:last-child) {
    width: 100%;
  }
  .currentSelectionsContainer ul > li:last-child {
    width: 100%;
  }
}
