.SMBCFormContainer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 35rem;
  justify-content: center;
  border-radius: 20px;
  margin: 0em auto;
}
.payForm {
  width: 100%;
  max-width: 30rem;
  margin: 2em auto;
}
.submitButton {
  box-sizing: border-box;
  width: 100%;
  max-width: 30rem;
  color: rgb(0, 125, 209);
  font-weight: 800;
  background-color: white;
  border: 1px solid rgb(3, 112, 255);
  border-radius: 1rem;
  padding: 0.4em 0;
  cursor: pointer;
}
