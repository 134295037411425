.modalContainer {
  box-sizing: border-box;
  border: none;
  /* width: 100%; */
  max-width: 90dvw;
  border-radius: 5px;
  margin: 0px 10px;
  box-sizing: border-box;
  margin: auto;
}
.modalContentContainer {
  position: relative;
}
.modalContainer:focus {
  outline: none;
}

@media (min-width: 768px) {
  .modalContainer {
    max-width: 90dvw;
  }
}
