.mouHeading {
  text-align: center;
  margin-top: 2em;
}
.agreementCard {
  margin: 2em 0;
}
.agreementMainPoint {
  background-color: rgb(230, 230, 230);
  padding: 1em;
  margin: 0;
}
.agreementDetail {
  padding: 1.5em 1em;
  font-size: 0.8rem;
  background-color: white;
}
.agreementMainPointIntroText {
  font-size: 1.6rem;
  font-weight: 900;
  margin: 1rem 0;
}
