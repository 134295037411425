.ApplicationCompleteStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.ApplicationCompleteStep > div {
  width: 100%;
  max-width: 45rem;
}
.applicationCompleteStepHeading {
  text-align: center;
}
.emailNoticeExplanation > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
}

.appCompleteNotice {
  padding: 1em;
  box-sizing: border-box;
  border: none;
  color: black;
  background-color: rgb(214, 231, 255);
  margin: 0 auto 2em auto;
}
.appFailureNotice {
  margin-top: 2em;
  margin-bottom: 5em;
}
.appFailureNotice div > p {
  text-align: center;
  margin: 0;
  font-weight: 800;
  font-size: 1.1rem;
}
.helpDetails {
  border: 2px solid rgb(255, 145, 0);
  margin-top: 2em;
}
.helpDetails > h3 {
  font-size: 1.4rem;
  text-align: center;
}
@media (min-width: 1520px) {
  .ApplicationCompleteStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 8em;
  }
}
