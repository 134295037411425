.noticeBox {
  min-height: 15rem;
}
.noticeBox > h2 {
  font-size: 1.7rem;
  text-align: center;
}
.noticeBox > p {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1em;
}
.outerSpin {
  box-sizing: border-box;
  margin: 2em auto;
  display: inline;
  position: relative;
  z-index: 999999;
}
.innerSpin {
  box-sizing: border-box;
  margin: auto;
  width: 70px;
  height: 70px;

  border: solid 8px transparent;

  border-top-color: #ec9f45;
  border-left-color: #ec9f45;

  border-radius: 100%;
  animation: loading-spinner 1000ms linear infinite;
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
