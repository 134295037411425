.emailEntry {
  box-sizing: border-box;
}
.instruction {
  /* display: block;
  padding: 0;
  border: 1px solid red; */
}
.required {
  margin: 0px;
}
.instructionList {
  box-sizing: border-box;
  padding: 0 1em;
  list-style: "※";
  margin-top: 0;
  margin-bottom: 1.5em;
}
.instructionList > li::before {
  /* content: "\\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em; */
}
.breakPoint {
  /* display: block; */
}
.instructionList li {
  font-size: 0.9rem;
  /* border: 1px solid red; */
}
.importantInstruction {
  font-size: 0.8rem;
  color: rgb(255, 115, 0);
}
.block {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
}

.required::before {
  content: "必須";
  background-color: rgb(255, 179, 79);
  color: white;
  font-size: 0.8rem;
  padding: 0.1em 0.3em;
  border-radius: 10px;
  margin-right: 0.5em;
}
.inputWrapper {
  width: 100%;
}
