.prefectureSectionContainer {
  position: relative;
  width: 100%;
  height: fit-content;
  margin-bottom: 5px;
}
.selectedPrefecture {
  box-sizing: border-box;
  font-size: 1.2rem;
  padding: 0.75em;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  outline: 1px solid rgb(121, 121, 121);
  width: 100%;
}
.moveableLabel {
  position: absolute;
  top: -0.3rem;
  left: 1em;
  pointer-events: none;
  transition: all 0.2s;
}
.selectedPrefecture:focus {
  outline: 1px solid rgb(121, 121, 121);
}
.prefListContainer {
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  background-color: white;
  max-height: 20rem;
  overflow-y: scroll;
  box-shadow: 1px 1px 10px rgb(187, 187, 187);
  scroll-behavior: smooth;
  scroll-margin: 0;
  border-radius: 5px;
  width: 100%;
  top: -5em;
}
.prefListContainer > li {
  padding: 0;
  margin: 0;
  border: none;
}
.prefListContainer > li > * {
  width: 100%;
}
.prefecture {
  box-sizing: border-box;
  /* border: 1px solid green; */
  padding: 0;
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
  padding: 0.7em;
  border: none;
  cursor: pointer;
}
.prefecture:focus-visible {
  background-color: rgb(156, 219, 255);
  border: none;
  outline: none;
}
.approved {
  outline: 2px solid rgb(0, 138, 0);
}
.isSelected {
  top: -1.85rem;
  left: 1em;
  background-color: white;
  color: rgb(0, 138, 0);
}
