.subscriptionApplicationContainer {
}
.subApplicationContainer {
}
.subscriptionApplicationHeading {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 4em;
  font-weight: 700;
}

.storeName {
  display: block;
  text-align: center;
  margin: 2em 0;
}
.prepInstruction {
  text-align: center;
  margin: 3em 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.appButton {
  width: 100%;
  max-width: 35rem;
  background-color: rgb(0, 153, 255);
  color: white;
  font-size: 1rem;
  border: none;
  padding: 5px 0;
  border-radius: 20px;
  cursor: pointer;
}

@media (min-width: 1420px) {
  .subscriptionApplicationContainer {
    margin-top: 10em;
  }
}
