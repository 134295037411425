.orderPageErrorContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
  min-height: 80vh;
  margin: 0 1em;
}
.orderPageErrorContainer h1 {
  text-align: center;
}
.returnButtonWrap {
  display: block;
  width: 100%;
  max-width: 30rem;
  margin: auto;
}
.returnButton {
  padding: 1em 0;
  width: 100%;
  margin: 1em 0;
  cursor: pointer;
  background-color: rgb(0, 174, 255);
  color: white;
  border: none;
  border-radius: 5px;
}
