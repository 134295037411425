.applicationContainer {
  margin: 2em 0;
}
.applicationDeliveryMode {
  display: flex;
  flex-direction: column;
}
.applicationDeliveryMode > label {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5em 0;
  /* border: 2px solid rgb(0, 149, 255); */
  font-size: 0.8rem;
  padding: 0.3em 1em;
  cursor: pointer;
}
.applicationContainerHeading {
  font-size: 1.1rem;
}
.applicationContainerHeading::before {
  content: "必須";
  background-color: rgb(255, 157, 65);
  color: white;
  font-size: 0.7rem;
  border-radius: 10px;
  padding: 0.1em 0.2em;
}
.mainApplicationType {
  accent-color: rgb(0, 110, 255);
  scale: 1.3;
  margin-right: 0.5rem;
}
@media (min-width: 960px) {
  .applicationDeliveryMode {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .applicationDeliveryMode > label {
    width: 49%;
    margin: 0.1rem 0;
  }
}
