.mainContentSection {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 83vh; */
}
.copyRightText {
  text-align: center;
}
