.block {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  position: relative;
}
.genders {
  display: flex;
  gap: 1em;
}
.genderBlock {
}
.inputBlock {
  display: flex;
  gap: 1.5em;
}
.required::before {
  content: "必須";
  background-color: rgb(255, 179, 79);
  color: white;
  font-size: 0.8rem;
  padding: 0.1em 0.3em;
  border-radius: 10px;
  margin-right: 1em;
}
.radioFormControl {
  accent-color: rgb(0, 140, 255);
}
