.ImeiContainer {
  /* border: 1px solid red; */
  position: relative;
}
.labelText {
  /* margin-left: 1rem; */
  font-size: 1.2rem;
}
.labelText::before {
  content: "必須";
  background-color: rgb(255, 179, 79);
  color: white;
  font-size: 0.7rem;
  padding: 2px 5px;
  border-radius: 10px;
  margin-right: 5px;
}
.labelContainer {
  position: relative;
}
.inputText {
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgb(168, 168, 168);
  font-size: 1.2rem;
  padding: 0.7em;
  width: 100%;
}
.labelapproved {
  color: rgb(0, 207, 0);
}
.labelError {
  color: red;
}
.moveableText {
  pointer-events: none;
  position: absolute;
  top: -0.1em;
  margin-left: 0.8rem;
  transition: all 0.1s;
  padding: 0 0.3em;
}
.entryInstruction {
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  /* border: 1px solid red; */
}
.entryContainer {
  height: 1rem;
  /* border: 1px solid blue; */
}
.inputText:focus {
  outline: none;
  border: 2px solid rgb(1, 141, 255);
}
.approved {
  border: 2px solid rgb(0, 189, 0);
}
.errorArea {
  color: red;
}
.inputText:focus + .moveableText {
  top: -1.3rem;
  background-color: white;
  font-size: 0.8rem;
  color: rgb(1, 141, 255);
}
.moveLabel {
  top: -1.3rem;
  background-color: white;
  font-size: 0.8rem;
}
.error {
  color: red;
  border: 2px solid red;
}
.emptyError {
  animation: empty 0.1s linear 0.1s 3 forwards;
}
@keyframes empty {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}
