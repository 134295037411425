.AgreementPageGenProvisions {
  margin-top: 5em;
  padding-bottom: 4em;
}
.genProvisionsHeading {
  text-align: center;
}
.agreementDetail {
  box-sizing: border-box;
  padding: 0px 1rem;
}
.agreementMainPoint {
  background-color: rgb(241, 241, 241);

  padding: 1em;
}

.articleTwo > div > p > span {
  font-weight: bolder;
  text-align: center;
  display: block;
  width: 100%;
}
.articleTwo > p {
  margin: 0;
}
.articleTwo > div > p {
  border: 0.1px solid black;
  margin: 0px;
  padding: 0 0.5em;
}
/* TABLE */
.genProvisionTableOne {
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
}
.genProvisionTableOne td {
  border-bottom: 1px solid black;
  padding: 0px 0.5em;
}
.firstDominantWidth {
  width: 700px;
  border-left: 1px solid black;
}
/* TABLE TWO FOR TERMS AND DEFINITIONS */
.termDefinitionsIntroText {
  margin: 0;
}
.termDefinitionsTable {
  border-collapse: collapse;
  margin-bottom: 4em;
}
.termDefinitionsTable tr:hover {
  background-color: rgb(240, 240, 240);
}
.termDefinitionsTable td,
.termDefinitionsTable th {
  border: 1px solid black;
  padding: 1em;
  font-size: 0.9rem;
}
.termDefinitionsTable td:first-child,
.termDefinitionsTable th:first-child {
  width: 25%;
}
.termDefinitionsTable th {
  text-align: left;
  user-select: none;
}
@media (min-width: 960px) {
  .termDefinitionsTable td:first-child,
  .termDefinitionsTable th:first-child {
    width: 15%;
  }
}
